import React from "react";
import { Helmet } from "react-helmet";
import Slider from "../components/home/SliderHeroesOne";
import Slider3 from "../components/home/SliderRSPCA";
import { Link } from "gatsby";
// import { Helmet } from "react-helmet";

// import Layout from "../components/layout"
// import SEO from "../components/seo"
// import VoucherAd from "../components/voucherAd";§
// import BackButton from "../components/backButton"
import SEO from "../components/seo";
import tw from "tailwind.macro";
import TopBar from "../components/Layout/TopBar";
import Navbar from "../components/Layout/Navbar";
import Footer from "../components/Layout/Footer";
import Seo from "../components/seo";
import Navigation from "../components/Layout/Navigation";
import DesktopMenu from "../components/Layout/Navigation/DesktopMenu";
import MenuControls from "../components/Layout/Navigation/MenuControls";
import MobileNavigation from "../components/Layout/Navigation/MobileNavigation";
import ReusableTiles from "../components/ReusableTiles";
import { css } from "@emotion/core";
import hero from '../images/hero/rspca-img-1.jpg';
import hero2 from '../images/hero/rspca-img-2.jpg';
import EntryFormButton from "../components/EntryFormButton";

// import "../scss/main.scss";

const Rspca2023 = () => (
    // <Layout>
  <div>
  {/* <SEO title="Supporting the RSPCA" keywords={[`great`, `british`, `dogs`]} /> */}
  <SEO
    title="greatbritishdogs.co.uk | Supporting the RSPCA"
    keywords={[`great`, `british`, `dogs`]}
  />
    <TopBar></TopBar>
    <Navigation>
      <MenuControls />
      <MobileNavigation></MobileNavigation>
    </Navigation>
    <DesktopMenu />

    <Slider3 />

    <div>
      <div className="container">
        <div
          className="prose max-w-2xl mx-auto py-12"
          css={css`
            h1 {
              ${tw`text-primary font-slab font-bold text-center `}
            }
            h2 {
              ${tw`text-lightCopy font-sans font-bold text-center `}
            }

            .accent {
              ${tw`text-accent`}
            }
          `}
        >
          <h1 css={css`
              display: none !important;
              `}>Tell us your Great British Dog story to help a dog in need</h1>
            <h2 css={css`
                margin-top: 0 !important; margin-bottom: 0 !important; font-size: 1.3em !important;text-align: left !important;
                `}>By sharing your story with Winalot®, you’ll be supporting the vital work at the RSPCA’s York Animal Home</h2>
            <p css={css`
                margin-top: 2em !important;
                `}>Even though some dogs haven’t found their forever homes yet, they still need wholesome, delicious meals to keep their tails wagging. That’s why, for every story shared with Great British Dogs, Winalot® will now donate a dog food pouch to the RSPCA’s York Animal Home*. What’s more, Immediate Media will match Winalot®’s donation. So, if you share your story, you’ll help provide two nutritious meals for dogs in need.</p>
                {/* <div css={css`position: relative; display: block; max-width: 1028px; margin: 1.5em 0;`}><div css={css`padding-top: 56.25%;`}><iframe src="https://players.brightcove.net/6192746955001/RoMNqN38g_default/index.html?videoId=6324011586112" allowfullscreen="" allow="encrypted-media" css={css`position: absolute; top: 0px; right: 0px; bottom: 0px; left: 0px; width: 100%; height: 100%;`}></iframe></div></div>*/}
                <p>Of course, the RSPCA wouldn’t be able to rescue, rehabilitate or rehome any of those dogs without its amazing staff. We spoke to two team members from the York Animal Home to find out about some of the Great British Dogs they’ve worked with, and how they think these pouch donations will help the charity.</p>
                <img src={hero} alt="Tig"/>
            <h3 className="text-primary font-slab font-bold text-left pb-5 text-2xl" css={css`margin-top: 0.7em !important; margin-bottom: 0em !important; color: #004030 !important; font-size: 1.5rem !important;`}>Meet Nicky</h3>
            <p css={css`font-weight: 600;`}><strong>After around 22 years of teaching people about animal welfare, Nicky joined the RSPCA 15 months ago as an Animal Care Assistant at the York Animal Home. Currently, she’s helping to rehabilitate dogs in the puppy unit, including an XL Bully cross called Tig (pictured above).</strong></p>
            <p>“He came in with four other littermates at just six months old,” Nicky says. “They had really stunted growth, as they’d spent a lot of time confined to a crate. Tig’s been with us a little while now, but he still has some behavioural issues, like nipping at people’s legs when he’s bored. He’s such a lovely boy, but he just doesn’t know how to behave like a dog. So, we’re trying to implement training plans and keep him occupied as much as we can.”</p>
            <div class="article-quote">
            <p>Having a <strong css={css`color: #004030 !important;`}>consistent supply</strong> of the same food is a real bonus for us</p>
            </div>
            <p>Nicky has already rehomed many different Great British Dogs at the RSPCA, but she’ll never forget Sparky the Chihuahua, who she met a few months after joining. “He was the cutest thing going, like a little old man,” she laughs. “I even gave him a jumper to keep him warm because he was bald on his back and neck. Eventually, someone applied for him, and they came to pick him up that day, which was very unusual. I wasn’t working, but I came to say goodbye. Well, I burst into tears when I handed him over! The new owner’s little boy pointed out that my jumper matched Sparky’s, and that just set me off.</p>
            <p>It’s so easy to get attached because you’re working with them day in, day out, and then suddenly you're handing them over. It's part of the job, but you can't help getting emotional. Sparky’s owners brought him to see me recently though, and they sent videos of his first Christmas in their home, which was really nice.”</p>
            <p className="bottom-paragraph">Naturally, none of Nicky’s work would be possible without donations, and she emphasises how much Winalot®’s food pouches will help: “Having a consistent supply of the same food is a real bonus for us. We’re relying heavily on donations at the minute, because of the cost-of-living crisis. Often, this means we’re having to give the dogs lots of different types of food, which can sometimes cause digestive issues, and might even cost more money if we have to get them checked at the vets. So, the Winalot® pouches will help us give some of them a more stable diet.”</p>
            <img src={hero2} alt="Cody"/>
            <h3 className="text-primary font-slab font-bold text-left pb-5 text-2xl" css={css`margin-top: 0.7em !important; margin-bottom: 0em !important; color: #004030 !important; font-size: 1.5rem !important;`}>Meet Ruth</h3>
            <p css={css`font-weight: 600;`}><strong>Since joining the RSPCA as a volunteer more than 20 years ago, Ruth has worked her way up to become an Animal Centre Manager, which involves overseeing the day-to-day running of the York Animal Home. Even though she has more responsibility now, it’s still rescuing the animals that gives her the most satisfaction.</strong></p>
            <p>“We take in these animals who have nothing and nowhere to go, and we completely turn their lives around,” she says. “We send hundreds of dogs to loving homes every year. It’s a real privilege, and it’s almost addictive, being able to save them like that.”</p>
            <p>One dog who made an impact on Ruth recently is a Staffy called Cody (pictured above): “He’s about 12, so he’s an older boy, which is always tough. You can tell he’s had a difficult past, as he came in very sad and lost, but everyone at the centre loves him. We have a little area where we can sit with the dogs, and he loves snuggling up to us. He just wants that closeness with somebody, and it’s heart-breaking because nobody’s applied for him yet. In the meantime, we’re just making sure he gets a few extra cuddles, and plenty of time out of his kennel.”</p>
            <div class="article-quote">
            <p>We send <strong css={css`color: #004030 !important;`}>hundreds</strong> of dogs to <strong css={css`color: #004030 !important;`}>loving homes</strong> every year</p>
            </div>
            <p>Ruth believes every dog at the RSPCA is a Great British Dog, even if they haven’t found the right owner yet. She recalls a group of four Spaniel crosses who came in after spending the first 10 months of their life isolated in a pen. “They’d never been touched by a human,” she explains, “so they were completely feral, and would just hide in their beds all day without moving. We started by sitting in their kennel for a few minutes at a time, then we progressed to gently touching them. It took us six or seven months, but I still remember the day we finally got them onto collars and leads. It was like something clicked after that – we started walking them regularly and they began to enjoy cuddles and attention. We ended up rehoming all four of them.”</p>
            <p>According to Ruth, large food donations like Winalot®’s can support the RSPCA’s work in several ways: “We’re a charity, so we’re struggling financially, and any food donations we get are so valuable to us. We get through a huge amount of food, so it helps us massively to not have to buy it ourselves. We also get a lot of dogs in who’ve previously been starved, so it’s really important for them to be on a consistent diet, and these pouches will help us do that.”</p>
            <p css={css`
                color: #ae456c !important; font-weight: bold !important;
                `}><Link
                to="https://www.greatbritishdogs.co.uk/submit-story-2024" css={css`
                color: #ae456c !important; font-weight: bold !important;
                font-family: Roboto Slab,sans-serif !important;`}>Share your story</Link> with Winalot® and help support the RSPCA</p>
                <p className="bottom-paragraph" css={css`font-size: 12px !important; line-height: 16px !important;`}>*Donations will go to RSPA York, Harrogate and District Branch (Registered Charity No. 232222). One story per dog owner. Combined donation capped at 10,000 pouches. T&Cs apply.</p>
            </div>
        </div>
    </div>
    <div className="bg-pale-100">
        <div className="container" css={css`padding-right:0; padding-left:0;`}>
          <ReusableTiles />
        </div>
    </div>
    <Footer></Footer>
  </div>

  // </Layout>
);

export default Rspca2023;

